import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { useIntl, FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import Img from "gatsby-image";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import OffersSlider from "../components/offers-slider";
import HotelSlider from "../components/hotel-slider";
import ServicesGrid from "../components/services-grid";
import ServicesSlider from "../components/services-slider";
import ActivitiesSlider from "../components/activities-slider";
import ServicesMap from "../components/services-map";
import ServiceModal from "../components/service-modal";
import Close from "../components/close";
import Search from "../components/search";

const HomeTemplate = ({ data, pageContext: context }) => {
  const intl = useIntl();
  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;
  const [modal, setModal] = useState("");

  const {
    title,
    teaser,
    description,
    long_description,
    address,
    lat,
    lon,
    email,
    phone,
    gallery,
    airport,
    station,
    tourist_office,
    hero_image,
    thumbnail,
    seo_content,
    booking_id,
    place_id,
    star_rating,
    price_range,
    rooms,
    guide,
    spirit,
    seo_site_hotel_teaser,
    seo_site_hotel_description,
    seo_site_hotel_long_description,
    seo_site_hotel_mid_page_content,
    seo_site_hotel_mid_page_image,
    seo_site_hotel_footer_content,
  } = data.contentfulHotel;

  const addressSegments = address.split(",");
  const streetAddress = addressSegments[0];
  const addressLocality = addressSegments.slice(-1)[0].replace(/^[\s\d]+/, "");
  const postalCode = addressSegments.slice(-1)[0].slice(0, 5);
  const [placeData, setPlaceData] = useState(null);
  const { rating, user_ratings_total } = placeData || {};

  function getPage(category) {
    return allPages.find((page) => page.category === category);
  }

  function toggleModal() {
    setModal(modal === "" ? " active" : "");
  }

  useEffect(() => {
    fetch(
      `https://ace-api.ultro.digital/place/details/json?place_id=${place_id}`
    )
      .then((response) => response.json())
      .then((data) => setPlaceData(data.result))
      .catch((error) => console.error(error));
  }, [place_id]);

  return (
    <Layout noButton>
      <Seo>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Hotel",
              "@id": "${siteUrl}/${context.locale}/#hotel",
              "url": "${siteUrl}/${context.locale}/",
              "name": "${`ACE Hôtel${
                spirit.slug === "travel" ? " Travel" : ""
              } ${title}`}",
              "logo": "${siteUrl}/logo${
            spirit.slug === "travel" ? "-travel" : "-simplement-bien"
          }.png",
              "image": "https:${
                hero_image ? hero_image.file.url : thumbnail.file.url
              }",
              "branchOf": {
                "@type": "Organization",
                "name": "ACE Hôtel"
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${streetAddress}",
                "addressLocality": "${addressLocality}",
                "postalCode": "${postalCode}",
                "addressCountry": "France"
              },
              "starRating": {
                "@type": "Rating",
                "ratingValue": "${star_rating || 3}"
              },
              ${
                rating
                  ? `
                  "aggregateRating": {
                    "ratingValue": "${rating}",
                    "ratingCount": "${user_ratings_total}",
                    "@type": "AggregateRating",
                    "bestRating": "5"
                  },
                `
                  : ""
              }
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${lat},
                "longitude": ${lon}
              },
              "email": "${email}@ace-hotel.com",
              "telephone": "${phone}",
              "currenciesAccepted": "EUR",
              "paymentAccepted": "Cash, Credit Card",
              "priceRange": "${price_range || "€"}",
              ${rooms ? `"numberOfRooms": "${rooms}",` : ""}
              "openingHours": "Mo-Su"
            }
          `}
        </script>
      </Seo>
      <section className="section section-white overflow-hidden">
        <div className="hero hero-hotel">
          <div className="hero-container">
            <div className="hero-inner">
              <div className="hero-image">
                {(hero_image || thumbnail) && (
                  <Img
                    fluid={hero_image ? hero_image.fluid : thumbnail.fluid}
                    loading="eager"
                    alt={`ACE Hôtel${
                      data.contentfulHotel.spirit.slug === "travel"
                        ? " Travel"
                        : ""
                    } ${title}`}
                  />
                )}
              </div>
              <div className="hero-content">
                <div className="hero-content-text">
                  <h1>
                    {`${data.contentfulTraductions.main_title} ACE Hôtel${
                      data.contentfulHotel.spirit.slug === "travel"
                        ? " Travel"
                        : ""
                    }`}
                    <span className="d-block text-nowrap">{title}</span>
                  </h1>
                  <p>{data.contentfulTraductions.subtitle}</p>
                </div>
                {booking_id && (
                  <div className="hero-content-form">
                    <Search booking_id={booking_id} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-white overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 pr-xl-10">
              <h2 className="heading mt-xl-6">
                <FormattedMessage id="index.offers.heading" />
              </h2>
              <p className="d-none d-xl-block">
                <Link to={`/${context.locale}/${getPage("Offres").slug}/`}>
                  <FormattedMessage id="index.offers.link" />
                </Link>
              </p>
            </div>
            <div className="col-8 col-sm-10 col-lg-12 col-xl-9">
              <LazyLoad>
                <OffersSlider />
                <div className="grid grid-offers d-none d-lg-grid">
                  {[
                    ...(data.contentfulHotel.offer
                      ? [data.contentfulHotel.offer]
                      : []),
                    ...data.allContentfulOffre.nodes,
                  ]
                    .filter(
                      (node, i, arr) =>
                        arr.findIndex((n) => n.slug === node.slug) === i
                    )
                    .slice(0, 3)
                    .map((node, i) => {
                      const {
                        url: iconUrl,
                        details: {
                          image: { width: iconWidth, height: iconHeight },
                        },
                      } = node.icon.file;

                      return (
                        <Link
                          className={`offer offer-${node.color}`}
                          to={`/${context.locale}/${getPage("Offres").slug}/${
                            node.slug
                          }/`}
                          key={i}
                        >
                          <div className="offer-heading">
                            {node.discount && <p>{node.discount}</p>}
                            {node.icon && (
                              <img
                                src={iconUrl}
                                width={iconWidth}
                                height={iconHeight}
                                alt={node.title}
                              />
                            )}
                          </div>
                          <div className="offer-description">
                            <h3>{node.title}</h3>
                            <p>{node.short_description}</p>
                            <p className="link">
                              <FormattedMessage id="more" />
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="mt-8 d-flex justify-content-end d-xl-none">
            <p>
              <Link to={`/${context.locale}/${getPage("Offres").slug}/`}>
                <FormattedMessage id="index.offers.link" />
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className="section section-clouds section-clouds-reverse">
        <div className="container">
          <div className="row">
            {gallery && (
              <div className="col-lg-6 col-xl-5 offset-xl-1">
                <h2 className="d-lg-none">{seo_site_hotel_teaser || teaser}</h2>
                <div className="position-relative">
                  <LazyLoad>
                    <HotelSlider data={gallery} />
                    {data.contentfulEsprit.logo && (
                      <Img
                        className="logo-absolute"
                        fluid={data.contentfulEsprit.logo.fluid}
                        alt={data.contentfulEsprit.title}
                      />
                    )}
                  </LazyLoad>
                </div>
              </div>
            )}
            <div
              className={
                gallery
                  ? "col-lg-5 col-xl-4 offset-lg-1 mt-4 mt-lg-0"
                  : "col-lg-8 offset-lg-2"
              }
            >
              <h2 className="d-none d-lg-block mt-6">
                {seo_site_hotel_teaser || teaser}
              </h2>
              {(seo_site_hotel_description || description) &&
                renderRichText(seo_site_hotel_description || description, {
                  renderText: (text) => {
                    return text
                      .split("\n")
                      .reduce((children, textSegment, index) => {
                        return [
                          ...children,
                          index > 0 && <br key={index} />,
                          textSegment,
                        ];
                      }, []);
                  },
                })}
              <p>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&origin=current+location&destination=ace+hotel+${address
                    .split(" ")
                    .join("+")}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>
                    {intl.formatMessage({ id: "google-itinerary" })}
                  </strong>
                </a>
              </p>
              <p className="d-lg-none">
                <a
                  href={`https://www.waze.com/ul?ll=${lat}%2C${lon}&navigate=yes&zoom=17`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>
                    {intl.formatMessage({ id: "waze-itinerary" })}
                  </strong>
                </a>
              </p>
            </div>
          </div>
          {(seo_site_hotel_long_description || long_description) && (
            <div className="row mt-16 mt-md-30">
              <div className="col-lg-6 offset-lg-3">
                {renderRichText(
                  seo_site_hotel_long_description || long_description,
                  {
                    renderText: (text) => {
                      return text
                        .split("\n")
                        .reduce((children, textSegment, index) => {
                          return [
                            ...children,
                            index > 0 && <br key={index} />,
                            textSegment,
                          ];
                        }, []);
                    },
                  }
                )}
              </div>
            </div>
          )}
          <div className="row flex-column-reverse flex-lg-row mt-16 mt-md-30">
            <div className="col-lg-6">
              <h2 className="d-none d-lg-block mt-6">
                {data.contentfulEsprit.main_title}
              </h2>
              <p className="text-blue-dark mt-8 mt-lg-6">
                {data.contentfulEsprit.short_description}
              </p>
              {data.contentfulEsprit.featured && (
                <div className="services">
                  {data.contentfulEsprit.featured.map((node, i) => {
                    const {
                      url,
                      details: {
                        image: { width, height },
                      },
                    } = node.file;

                    return (
                      <div className="services-item" key={i}>
                        <ServiceModal node={node}>
                          <img
                            src={url}
                            width={width}
                            height={height}
                            alt={node.title}
                          />
                          <p>
                            <span>{node.title}</span>
                          </p>
                        </ServiceModal>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="d-flex">
                <p className="mb-0">
                  <Link
                    className="link"
                    to={`/${context.locale}/${getPage("Esprits").slug}/${
                      data.contentfulEsprit.slug
                    }/`}
                  >
                    <FormattedMessage id="more" />
                  </Link>
                </p>
                <p className="ml-10 mt-0">
                  <button
                    className="link"
                    onClick={toggleModal}
                    aria-label="Toggle Modal"
                  >
                    <FormattedMessage id="index.services.button" />
                  </button>
                </p>
              </div>
              <div className={`modal modal-services${modal}`}>
                <div className="modal-content">
                  <button
                    className="modal-close"
                    onClick={toggleModal}
                    aria-label="Close Modal"
                  >
                    <Close />
                  </button>
                  <div className="container modal-scroll">
                    <ServicesGrid />
                  </div>
                </div>
                <button
                  className="modal-backdrop"
                  aria-label={intl.formatMessage({ id: "close" })}
                  onClick={toggleModal}
                ></button>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h2 className="d-lg-none">{data.contentfulEsprit.main_title}</h2>
              {(data.contentfulEsprit.thumbnail ||
                data.contentfulEsprit.red_room ||
                data.contentfulHotel.spirit_thumbnail) && (
                <Img
                  fluid={
                    data.contentfulHotel.spirit_thumbnail
                      ? {
                          ...data.contentfulHotel.spirit_thumbnail.fluid,
                          aspectRatio: 460 / 610,
                        }
                      : data.contentfulHotel.red_room === true
                      ? {
                          ...data.contentfulEsprit.red_room.fluid,
                          aspectRatio: 460 / 610,
                        }
                      : {
                          ...data.contentfulEsprit.thumbnail.fluid,
                          aspectRatio: 460 / 610,
                        }
                  }
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blue overflow-hidden">
        <div className="container">
          <h2>{data.contentfulPage.main_title}</h2>
          <p className="text-blue-dark">{data.contentfulPage.subtitle}</p>
          <div className="row mt-6 mt-md-10">
            <div className="col-8 col-sm-10 col-lg-12">
              <LazyLoad>
                <ServicesSlider />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-white overflow-hidden">
        <div className="search">
          <div className="search-container">
            {modal !== " active" ? (
              <>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <h2 className="mb-4 mb-md-0">
                        <FormattedMessage id="map.access" />
                      </h2>
                    </div>
                  </div>
                </div>
                <LazyLoad>
                  <ServicesMap />
                </LazyLoad>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      {(airport || station || tourist_office) && (
        <section className="section section-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 mb-6 mb-xl-0 pr-xl-10">
                <h2>
                  <FormattedMessage id="links" />
                </h2>
              </div>
              {airport && (
                <div className="col-md-4 col-xl-3 pr-md-10">
                  {renderRichText(airport, {
                    renderNode: {
                      [INLINES.HYPERLINK]: (node, children) => {
                        return (
                          <a
                            href={node.data.uri}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {children}
                          </a>
                        );
                      },
                    },
                  })}
                </div>
              )}
              {station && (
                <div className="col-md-4 col-xl-3 mt-4 mt-md-0 pr-md-10">
                  {renderRichText(station, {
                    renderNode: {
                      [INLINES.HYPERLINK]: (node, children) => {
                        return (
                          <a
                            href={node.data.uri}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {children}
                          </a>
                        );
                      },
                    },
                  })}
                </div>
              )}
              {tourist_office && (
                <div className="col-md-4 col-xl-3 mt-4 mt-md-0 pr-md-10">
                  {renderRichText(tourist_office, {
                    renderNode: {
                      [INLINES.HYPERLINK]: (node, children) => {
                        return (
                          <a
                            href={node.data.uri}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {children}
                          </a>
                        );
                      },
                    },
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      {seo_site_hotel_mid_page_content && (
        <section className="section section-white">
          <div className="container">
            <div className="row">
              {seo_site_hotel_mid_page_image && (
                <div className="col-lg-6 col-xl-5 offset-xl-1">
                  <Img
                    fluid={{
                      ...seo_site_hotel_mid_page_image.fluid,
                      aspectRatio: 460 / 610,
                    }}
                    alt={`ACE Hôtel${
                      data.contentfulHotel.spirit.slug === "travel"
                        ? " Travel"
                        : ""
                    } ${title}`}
                  />
                </div>
              )}
              <div
                className={
                  seo_site_hotel_mid_page_image
                    ? "col-lg-5 col-xl-4 offset-lg-1 mt-8 mt-lg-0"
                    : "col-lg-8 offset-lg-2"
                }
              >
                {renderRichText(seo_site_hotel_mid_page_content, {
                  renderNode: {
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="mt-lg-6">{children}</h2>
                    ),
                  },
                })}
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="section section-blue overflow-hidden">
        <div className="container">
          <h2>{guide.main_title}</h2>
          {guide.content && renderRichText(guide.content)}
          <div className="row mt-6 mt-md-10">
            <div className="col-8 col-sm-10 col-lg-12 position-static">
              <LazyLoad>
                <ActivitiesSlider />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
      {(seo_site_hotel_footer_content || seo_content) && (
        <section className="section section-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {renderRichText(seo_site_hotel_footer_content || seo_content, {
                  renderNode: {
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="mt-lg-6">{children}</h2>
                    ),
                  },
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default HomeTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!, $hotel: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      seo_title
      meta_description
      teaser
      description {
        raw
      }
      long_description {
        raw
      }
      gallery {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      address
      lat
      lon
      slug
      email
      phone
      booking_id
      place_id
      business_url
      star_rating
      price_range
      rooms
      radius
      airport {
        raw
      }
      station {
        raw
      }
      tourist_office {
        raw
      }
      thumbnail {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      hero_image {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      spirit_thumbnail {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      seo_content {
        raw
      }
      seo_image {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
      guide {
        main_title
        content {
          raw
        }
      }
      offer {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      red_room
      spirit {
        slug
      }
      seo_site_hotel_title
      seo_site_hotel_meta_description
      seo_site_hotel_teaser
      seo_site_hotel_description {
        raw
      }
      seo_site_hotel_long_description {
        raw
      }
      seo_site_hotel_mid_page_content {
        raw
      }
      seo_site_hotel_mid_page_image {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      seo_site_hotel_footer_content {
        raw
      }
    }
    contentfulTraductions(node_locale: { eq: $locale }) {
      main_title
      subtitle
    }
    contentfulComparatifTableau {
      content {
        title
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        simplement_bien
        simplement_bien_content
        travel
        travel_content
      }
    }
    allContentfulOffre(
      filter: {
        hotel: { elemMatch: { slug: { eq: $hotel } } }
        active: { ne: false }
        type: { in: ["Offre permanente", "Offre ponctuelle"] }
        node_locale: { eq: $locale }
      }
      sort: { fields: updatedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
    allContentfulActivite(
      filter: { hotel: { slug: { eq: $hotel } }, node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        node_locale
      }
    }
    contentfulEsprit(
      hotel: { elemMatch: { slug: { eq: $hotel } } }
      node_locale: { eq: $locale }
    ) {
      title
      main_title
      short_description
      slug
      logo {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featured {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
      icon_gallery {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
      thumbnail {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      red_room {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    contentfulPage(
      contentful_id: { eq: "45OcEyajvmUzF2zPKixst5" }
      node_locale: { eq: $locale }
    ) {
      main_title
      subtitle
    }
    allContentfulService(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }

    allContentfulPointDinteret(filter: { node_locale: { eq: $locale } }) {
      nodes {
        category
        place_id
        title
        url
        vicinity
        lat
        lng
        hotel {
          title
          slug
        }
      }
    }
  }
`;
